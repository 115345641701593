import React from 'react'
import Navbar from '../components/Navbar';
import Registeration from '../components/Registeration';
import Footer from '../components/Footer';

const Register = () => {
  return (
    
    <div>
      <Navbar/>
      <Registeration/>
      <Footer />
    </div>
  )
}

export default Register;