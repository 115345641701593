import React from 'react'
import "./HomePage.css";



export const HomePage = () => {
    return (

        <div className='grid'>
            <div className='img-wrapper1'>
                <img  src='images/maadurga.jpg' />
                <div className="content blur fade">
                    <p>
                        We are Kitchener Waterloo Cambridge 
                        Guelp Bengali Cultural Group !
                    </p>
                </div>
            </div>
           
        </div>

    )
}

export default HomePage;
