import React from 'react'
import "./Events.css";


export const HomePage = () => {
    return (
        <div class="main">
            <h3 class="heading"> Events </h3>

            <div class="Timeline">
 
                <div className="stiched" >
                    <h2>2023 Events - October</h2>
                    <img className="img" src='images/dp-2023-1.jpg' />
                   
                </div>
             
            </div>
        </div>



    )
}

export default HomePage;
