import "./GalleryCard.css";
import "./Gallery1.js"



const GalleryCard = () => {
  return (
    <div class="grids">
      <img src="images/dp2010-1.png" alt="" />
      
      <img src="images/saraswatipuja2018.jpg" alt="" />
      <img src="images/durgapuja2018.jpg" alt="" />
      <img src="images/durgapuja2017.jpg" alt="" />
      <img src="images/picnic2017.jpg" alt="" />
      <img src="images/gardenparty2017.jpg" alt="" />
      <img src="images/durgapuja2016.jpg" alt="" />
      <img src="images/durgapuja2015.jpg" alt="" />
      <img src="images/durgapuja2014.jpg" alt="" />
      <img src="images/picnic2022.jpg" alt="" />
      <img src="images/picnic2015.jpg" alt="" />
      <img src="images/function.jpg" alt="" />
      <img src="images/kolorob-1.png" alt="" />
      <img src="images/function-2.png" alt="" />
      <img src="images/dp2022-1.png" alt="" />
    </div>

  )
}

export default GalleryCard;