import "./Registeration.css"
import React from 'react'


const MainContent = () => {
    return (
        <div className="mainRegister">
            <div className="stiched">
            <img className="img" src='images/sponsor.png' />
            </div>
        </div>
    )
};

export default MainContent;