import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import HomePage from '../components/HomePage';

const Landing = () => {
  return (
    <div>
      <Navbar/>
      <HomePage/>
      <Footer />
    </div>
  )
};

export default Landing