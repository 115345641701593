import React from 'react'
import AboutUsContent from '../components/AboutUsContent';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
const AboutUs = () => {
  return (
    <div>
      <Navbar/>
      <AboutUsContent/>
      <Footer />
    </div>
  )
}

export default AboutUs;